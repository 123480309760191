<template>
  <ion-page>
    <ion-content fullscreen class="ion-padding" scroll-y="false">
      Tab 3
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonContent, 
  IonPage 
} from '@ionic/vue';

export default  {
  name: 'Tab3',
  components: { 
    IonContent, 
    IonPage 
  }
}
</script>